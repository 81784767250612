<template>
  <div class="SimpleDentistList">
    <v-img :src="require('./../../../assets/images/pexels-tima-miroshnichenko-5355917.jpg')" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
      <h1 class="ma-3">Zahnärzte im Landkreis Spree-Neiße</h1>
    </v-img>
    <v-toolbar flat class="my-3 fixPadding">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Suche" clearable outlined single-line hide-details class="mr-2"></v-text-field>
      <v-select hide-details label="Nach Ort filtern" outlined clearable
        v-model="locationFilter"
        :items="allLocations"
      ></v-select>
    </v-toolbar>

    <v-row class="mt-1">
      <div class="col-md-4 col-sm-6" v-for="dentist in filteredData" :key="dentist.id">
        <v-card flat class="dentistCard">
          <v-card-text>
            <h3 class="my-3">{{ $helper.getDentistFullName(dentist) }}</h3>
            <p class="">{{dentist.company_city}}</p>
            <v-btn color="primary" @click="showDentist(dentist)" block>Details anzeigen</v-btn>
          </v-card-text>
        </v-card>
      </div>
    </v-row>

    <v-dialog v-model="dentistDialog.modal" max-width="500">
      <v-card>
        <v-img :src="require('./../../../assets/images/pexels-cedric-fauntleroy-4269694.jpg')" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
          <v-card-title>Zahnarzt Kontaktdaten</v-card-title>
        </v-img>
        <v-btn class="modalClose" dark @click="dentistDialog.modal=false">&times;</v-btn>
        <v-card-text class="pt-5" v-if="dentistDialog.dentist">
          <Dentist :dentist="dentistDialog.dentist"></Dentist>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { simpleListDentist } from '../api';
import Dentist from './Dentist.vue';
export default {
  props:{},
  components: { Dentist },
  mounted() {
    this.loadDentists();
  },
  data:() => ({
    loading: false,
    data: [],
    search: '',
    locationFilter: '',
    headers: [
      { value: 'name', text: 'Name' },
      { value: 'company_city', text: 'aus' },
    ],
    itemsPerPage: 10,

    dentistDialog: {
      modal:false,
      dentist:null
    }
  }),
  computed:{
    filteredData() {
      let result = this.data.filter(elem => true);
      if(this.locationFilter && this.locationFilter.length>0) {
        console.log('location', this.locationFilter);
        result = result.filter(elem => elem.company_city === this.locationFilter);
      }
      result = result.filter(elem => this.searchFilter(null, this.search, elem));
      return result;
    },
    allLocations() {
      let result = this.data.map(elem => elem.company_city);
      result = [...new Set(result)];//all unique entries
      result.sort((a, b) => a.localeCompare(b));
      return result;
    }
  },
  methods:{
    searchFilter(value, search, item) {
      if(!search) search = '';
      let searchChunks = search.toLocaleLowerCase().split(' ');
      let results = searchChunks.map(elem => 
        item.firstname.toLocaleLowerCase().indexOf(elem) > -1
        || item.lastname.toLocaleLowerCase().indexOf(elem) > -1
        || item.company_city.toLocaleLowerCase().indexOf(elem) > -1
      );
      return results.every(x => x===true);
    },
    loadDentists() {
      if(this.loading) return;
      this.loading = true;
      
      simpleListDentist()
        .then(response => {
          this.loading = false;
          this.data = response.data;
        })
        .catch(() => {
          this.loading = false;
        })
    },
    showDentist(entry) {
      this.dentistDialog = {
        modal:true,
        dentist:entry
      };
    }
  }
}
</script>